import React, { ReactNode } from 'react';
import { OnchainKitProvider } from '@coinbase/onchainkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { baseSepolia } from 'viem/chains';
import { WagmiProvider } from 'wagmi';
import { createWagmiConfig } from '../src/store/createWagmiConfig'; // Adjust this import path as needed


const queryClient = new QueryClient();

const rpcUrl = 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/dOKRBsUwZ-yWuyJiJn03Fdbjnt52uw5W';

const wagmiConfig = createWagmiConfig(rpcUrl);

function OnchainProviders({ children }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <OnchainKitProvider chain={baseSepolia}>{children}</OnchainKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default OnchainProviders;