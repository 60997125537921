import React, { useEffect } from 'react';
import { useCallsStatus } from 'wagmi/experimental';

/**
 * CallStatus uses the call id to fetch and display the status of a writeContracts call.
 * It also triggers a parent component refresh when the status is confirmed.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The id of the call to fetch status for.
 * @param {Function} props.onConfirmed - Callback function to refresh the parent component.
 * @returns {JSX.Element} A div element displaying the current status of the call.
 */
export function CallStatus({ id, onConfirmed }) {
  const { data: callsStatus } = useCallsStatus({
    id,
    query: {
      refetchInterval: (data) =>
        data?.state?.data?.status === 'CONFIRMED' ? false : 1000,
    },
  });

  useEffect(() => {
    if (callsStatus?.status === 'CONFIRMED') {
      onConfirmed();
    }
  }, [callsStatus?.status, onConfirmed]);

  return (
    <div>
      <strong>Minting Status:</strong> {callsStatus?.status ?? 'loading'}
    </div>
  );
}