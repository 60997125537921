/**
 * Where is this application currently running?
 * This will be used to drive configurations for the application
 * based on the environment.
 * @readonly
 * @enum {string}
 */
export const Environment = {
    localhost: 'localhost', // Local Environment
    development: 'development', // Development & Testing Environment
    staging: 'staging', // Staging Environment which should mimic production
    production: 'production', // Production Environment
  };
  
  /**
   * Environment keys used in process.env
   * @readonly
   * @enum {string}
   */
  export const EnvironmentKeys = {
    environment: 'NEXT_PUBLIC_ENVIRONMENT',
  };
  
  /**
   * Get the current environment of the application
   * @returns {string} The current environment
   */
  export function getCurrentEnvironment() {
    const stage = process.env[EnvironmentKeys.environment];
  
    if (stage === undefined) {
      return Environment.localhost;
    }
  
    // Convert string to Environment value
    const environmentValue = Object.values(Environment).find((value) => value === stage);
  
    return environmentValue || Environment.localhost;
  }
  