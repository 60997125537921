import React from 'react';
import FooterColumn from './FooterColumn';
import qrCode from '../image/QR.png';

function Footer() {
  const footerColumns = [
    {
      title: 'Products',
      items: [
        { name: 'DaylightX Monitor', url: 'https://uat.godaylight.com/monitor/' },
        { name: 'Solar', url: 'https://uat.godaylight.com/solar/' },
        { name: 'Battery', url: 'https://uat.godaylight.com/battery/' },
        { name: 'Heat Pump', url: 'https://uat.godaylight.com/heat-pump/' },
        { name: 'Water Heater', url: 'https://uat.godaylight.com/heat-pump-water-heater/' },
        { name: 'Weatherization', url: 'https://uat.godaylight.com/weatherization/' },
      ],
    },
    // {
    //   title: 'Blog',
    //   items: [
    //     { name: 'Latest Posts', url: 'https://blog.external-site.com/latest' },
    //     { name: 'Categories', url: 'https://blog.external-site.com/categories' },
    //     { name: 'RSS Feed', url: 'https://blog.external-site.com/rss' },
    //     { name: 'Subscribe', url: 'https://blog.external-site.com/subscribe' },
    //   ],
    // },
    {
      title: 'About',
      items: [
        { name: 'How it works', url: 'https://uat.godaylight.com/how-it-work' },
        { name: 'Service areas', url: 'https://uat.godaylight.com/service-area/' },
        { name: 'FAQs', url: 'https://help.godaylight.com/en' },
      ],
    },
    {
      title: '',
      items: [
        { name: 'Terms of Service', url: 'https://uat.godaylight.com/legal/terms/' },
        { name: 'Privacy Policy', url: 'https://uat.godaylight.com/legal/privacy ' },
      ],
      copyright: '©2024 Daylight',
    },
  ];

  return (
    <footer className="bg-black text-white md:p-10 p-2 mt-10">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between md:items-start">
          <div className="flex flex-col md:flex-row justify-between items-start w-3/5 md:w-full">
            {footerColumns.map((column, index) => (
              <FooterColumn key={index} {...column} />
            ))}
          </div>
          <div className="w-2/5 md:w-full">
            <div className="flex flex-col lg:items-end md:items-start">
              {/* <div className='border-2 border-white rounded-md p-3 max-w-36'>
                <h6 className='text-md font-medium text-center mb-2'>Download the Daylight App</h6>
                <div className="md:mt-3 lg:m-0">
                  <img alt="Download the Daylight App QR Code" src={qrCode} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;