import React from 'react';

function BenefitsList() {
  const benefits = ['Early access to future projects', 'Exclusive community membership', 'Voting rights on ecosystem decisions'];

  return (
    <div className="mt-4 pb-6 border-b border-gray">
      <h2 className="text-xl font-semibold mb-2">Benefits</h2>
      <ul className="list-disc list-inside text-muted-foreground pl-3">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex items-center mb-1">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.66683 18.75L6.0835 16.0833L3.0835 15.4167L3.37516 12.3333L1.3335 10L3.37516 7.66667L3.0835 4.58333L6.0835 3.91667L7.66683 1.25L10.5002 2.45833L13.3335 1.25L14.9168 3.91667L17.9168 4.58333L17.6252 7.66667L19.6668 10L17.6252 12.3333L17.9168 15.4167L14.9168 16.0833L13.3335 18.75L10.5002 17.5417L7.66683 18.75ZM9.62516 12.9583L14.3335 8.25L13.1668 7.04167L9.62516 10.5833L7.8335 8.83333L6.66683 10L9.62516 12.9583Z" fill="#FF5900"></path>
            </svg>
            <span className="font-lg font-light text-gray-500 pl-2">{benefit}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BenefitsList;