import { useAccount } from 'wagmi';
import { getChainsForEnvironment } from '../store/supportedChains'; // Adjust this import path as needed

/**
 * Generates a hook that returns contract data based on the current network.
 * @param {Object} spec - The specification object containing ABI and chain-specific contract instances
 * @param {Array} spec.abi - The ABI of the contract
 * @param {Object} spec.[chainId] - Contract instances for each supported chain
 * @returns {Function} A React hook that provides contract data
 */
export function generateContractHook(spec) {
  const { abi, ...chainSpecs } = spec;

  /**
   * @typedef {Object} ContractHookResult
   * @property {Array} abi - The contract ABI
   * @property {Array} supportedChains - List of supported chains
   * @property {string} [address] - The contract address (if ready)
   * @property {string} status - The status of the contract ('ready', 'onUnsupportedNetwork', or 'deactivated')
   */

  /**
   * React hook for accessing contract data
   * @returns {ContractHookResult} The contract data and status
   */
  function useContract() {
    const { chain: accountChain } = useAccount();
    const supportedChains = Object.values(chainSpecs).map((s) => s.chain);

    // use a supported chain available in current env as fallback
    const chain =
      accountChain ||
      supportedChains.find((supportedChain) =>
        getChainsForEnvironment().some((envChain) => supportedChain.id === envChain.id)
      );

    if (chain && chainSpecs[chain.id]) {
      if (chainSpecs[chain.id].deactivated) {
        return { abi, status: 'deactivated', supportedChains };
      }

      return {
        abi,
        address: chainSpecs[chain.id].address,
        status: 'ready',
        supportedChains,
      };
    }

    return {
      abi,
      status: 'onUnsupportedNetwork',
      supportedChains,
    };
  }

  return useContract;
}