import React from 'react';
import nftImage from '../image/Dawn-of-Daylight-NFT.png'; // Adjust the path as needed

function NFTImage(props) {
  return (
    <div className="flex-1 mb-6 md:mb-0">
      <div className="bg-gray-200 p-5 rounded-lg shadow-lg">
        <h2 className="text-md font-semibold">Dawn of Daylight NFT</h2>
        <p className="text-light text-gray-700 mb-3">Limited Edition NFT</p>
        <img className="rounded-lg w-full" src={nftImage} alt="Dawn of Daylight NFT" />
        <div className="mt-3">
          {/* <p className="text-muted-foreground">YOUR NAME</p> */}
          <p className="text-muted-foreground">MINT:<span className="ml-2">{props.hasMinted ? 'MINTED' : 'PENDING'} </span></p>
          <p className="font-mono flex text-[#FF5900] font-medium">
          <svg class="mr-1" width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.33051 11.1909L7.56364 15.462C7.60306 15.5018 7.6633 15.516 7.7161 15.4958C8.04481 15.3719 8.36311 15.2279 8.66877 15.0628C8.75356 15.0178 8.76917 14.9015 8.7015 14.8332L4.21031 10.3017C4.0995 10.1899 4.22146 10.0053 4.36648 10.0654L11.0947 12.877C11.155 12.9025 11.2249 12.8837 11.2643 12.8312C11.4643 12.5648 11.6488 12.2857 11.8154 11.9946C11.8585 11.9195 11.825 11.8227 11.7455 11.789L4.84394 8.90456C4.69892 8.84378 4.74205 8.62618 4.89897 8.62618H12.7167C12.7918 8.62618 12.855 8.56765 12.8603 8.49111C12.8707 8.32828 12.8774 8.16395 12.8774 7.99812C12.8774 7.83229 12.8707 7.66796 12.8603 7.50514C12.855 7.42935 12.7918 7.37007 12.7167 7.37007H4.89897C4.74205 7.37007 4.69892 7.15246 4.84394 7.09169L11.7455 4.20729C11.825 4.17427 11.8585 4.07748 11.8154 4.00169C11.6488 3.71055 11.4651 3.43141 11.2643 3.16503C11.2249 3.11251 11.155 3.0945 11.0947 3.11926L4.36648 5.93087C4.22146 5.99165 4.0995 5.80706 4.21031 5.69451L8.70224 1.16606C8.76992 1.09778 8.7543 0.981475 8.66952 0.936454C8.36312 0.772124 8.04481 0.627304 7.71684 0.504244C7.66404 0.484735 7.60454 0.498241 7.56438 0.538011L3.33126 4.80908C3.22045 4.92088 3.0375 4.79782 3.09699 4.6515L4.92426 0.200347C4.96367 0.105051 4.89377 0 4.79188 0H3.75516C3.69716 0 3.6451 0.0352671 3.62279 0.0892933L1.94724 4.17052C1.887 4.31684 1.67132 4.27332 1.67132 4.11499V0.14482C1.67132 0.0652816 1.60737 0 1.52779 0H0.643534C0.563958 0 0.5 0.0645313 0.5 0.14482V15.8552C0.5 15.9347 0.563958 16 0.643534 16H1.52779C1.60662 16 1.67132 15.9355 1.67132 15.8552V11.8843C1.67132 11.7259 1.887 11.6824 1.94724 11.8287L3.62279 15.91C3.6451 15.964 3.69716 15.9993 3.75516 15.9993H4.79188C4.89377 15.9993 4.96367 15.8942 4.92426 15.7989L3.09699 11.3477C3.03675 11.2014 3.2197 11.0784 3.33126 11.1902L3.33051 11.1909Z" fill="#FF5900"></path>
            </svg>
            {props.tokenId}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NFTImage;