import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useAccount, useReadContract } from 'wagmi';
import { useWriteContracts } from 'wagmi/experimental';
import { baseSepolia } from 'viem/chains';
import { Loader2 } from 'lucide-react'; // Import the spinner icon
import { ConnectWallet } from '@coinbase/onchainkit/wallet';

import qrCode from '../image/QR.png';

import NFTImage from './NFTImage';
import { abi } from '../_contracts/DaylightNFTABI';
import { generateContractHook } from '../hooks/contacts';
import { CallStatus } from './CallStatus';
import BenefitsList from './BenefitsList';
import useTokenFromUrl from '../hooks/useTokenFromUrl';
import useDeviceDetect from '../utils/detectDevice';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const PINATA_GATEWAY_URL = process.env.REACT_APP_PINATA_GATEWAY_URL;
const DEFAULT_URL = process.env.REACT_APP_DEFAULT_URL;
const OPENSEA_BASE_URL = process.env.REACT_APP_OPENSEA_BASE_URL;
const NFT_URI = process.env.REACT_APP_NFT_URI;
const GRAPH_API_URL = process.env.REACT_APP_GRAPH_API_URL;

function NFTDetails() {
    const [refreshKey, setRefreshKey] = useState(0);
    const [tokenData, setTokenData] = useState([0, ""]);
    const [tokenId, setTokenId] = useState("DE########");
    const [hasMinted, setHasMinted] = useState(false);


    const [loading, setLoading] = useState(false);
    const [nftImageUrl, setNftImageUrl] = useState('');
    const [isMinting, setIsMinting] = useState(false);

    const { address } = useAccount();
    const token = useTokenFromUrl();
    const deviceType = useDeviceDetect();

    const { data: callID, writeContracts } = useWriteContracts();
    const { data: tokenDetails, refetch: refetchTokenDetails } = useReadContract({
        address: CONTRACT_ADDRESS,
        abi: abi,
        functionName: 'getTokenDetails',
        args: address ? [address] : undefined,
    });

    const { data: tokenIdResponse} = useReadContract({
        address: CONTRACT_ADDRESS,
        abi: abi,
        functionName: 'lastTokenId',
    });

    const contract = generateContractHook({
        abi: abi,
        [baseSepolia.id]: {
            chain: baseSepolia,
            address: CONTRACT_ADDRESS,
        },
    })();

    const fetchNFTMetadata = useCallback(async (tokenURI) => {
        setLoading(true);
        try {
            const response = await axios.get(tokenURI);
            const metadata = response.data;
            if (metadata.image) {
                let imageUrl = metadata.image;
                if (imageUrl.startsWith('ipfs://')) {
                    imageUrl = `${PINATA_GATEWAY_URL}${imageUrl.slice(7)}`;
                }
                setNftImageUrl(imageUrl);
            }
        } catch (error) {
            console.error('Error fetching NFT metadata:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const updateTokenData = useCallback(() => {
        if (tokenDetails) {
            console.log(tokenDetails[0], Number(tokenDetails[0]), typeof(tokenDetails[0]), 'tokenDetails[0]')
            if (tokenDetails[0] !== 0 && tokenDetails[1] !== "") {
                setTokenData(tokenDetails[0] === 0 ? [0, ""] : tokenDetails);
                setHasMinted(true);
                void fetchNFTMetadata(tokenDetails[1]);
                const formattedId = formatTokenId(Number(tokenDetails[0]));
                setTokenId(formattedId);
            }
        }
    }, [tokenDetails, fetchNFTMetadata]);

    const formatTokenId = useCallback((number) => {
        const paddedNumber = number.toString().padStart(8, '0');
        return `DE${paddedNumber}`;
    }, []);

    useEffect(() => {
        console.log("tokenId", tokenIdResponse);
        if (tokenIdResponse !== undefined) {
            const formattedId = formatTokenId(Number(tokenIdResponse) == 0 ? Number(tokenIdResponse) : Number(tokenIdResponse) + 1);
            setTokenId(formattedId);
        }
    }, [tokenIdResponse, formatTokenId]);

    useEffect(() => {
        console.log('token', token, 'deviceType', deviceType);
        console.log(tokenDetails, 'tokenDetails');
        updateTokenData();
    }, [tokenDetails, token, deviceType, updateTokenData]);

    useEffect(() => {
        if (address && token) {
            void updateUserWallet();
        }
    }, [address, token]);

    const handleConfirmed = useCallback(() => {
        setIsMinting(false);
        setHasMinted(true);
        console.log('Minting confirmed! Refreshing data...');
        setHasMinted(true);
        setRefreshKey(prevKey => prevKey + 1);
        refetchTokenDetails().then(() => {
            updateTokenData();
        });
    }, [refetchTokenDetails, updateTokenData]);


    const handleMint = () => {
        setIsMinting(true);
        writeContracts({
            contracts: [
                {
                    address: contract.address,
                    abi: contract.abi,
                    functionName: 'mint',
                    args: [address],
                },
            ],
            capabilities: {
                paymasterService: {
                    url: DEFAULT_URL,
                },
            },
        });
    };

    const handleViewNFT = () => {
        if (tokenData[0] !== 0) {
            window.location.href = `${OPENSEA_BASE_URL}${CONTRACT_ADDRESS}/${tokenData[0]}`;
        } else {
            alert('No NFT minted for this address.');
        }
    };

    const updateUserWallet = async () => {
        try {
            const response = await axios.post(
                GRAPH_API_URL,
                {
                    query: `
                        mutation UpdateUserWalletMutation {
                            updateUser(input: {walletAddress: "${address}"}) {
                                user {
                                    walletAddress
                                }
                            }
                        }
                    `
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error updating user wallet:', error);
        }
    };

    const renderButton = () => {
        if (!address || address.status === 'disconnected') {
            return (
                <ConnectWallet className="bg-[#FF5900] text-white w-full my-6 px-2 py-3 font-semibold font-lg rounded-md hover:bg-[#FF5900]/80 transition duration-200" />                
            );
        }
        return tokenData[0] !== 0 ? (
            <button
                className="bg-[#FF5900] text-white w-full my-6 px-2 py-3 font-semibold font-lg rounded-md hover:bg-[#FF5900]/80 transition duration-200"
                onClick={handleViewNFT}
            >
                View NFT
            </button>
        ) : (
            <button
                className="bg-[#FF5900] text-white w-full my-6 px-2 py-3 font-semibold font-lg rounded-md hover:bg-[#FF5900]/80 transition duration-200 flex items-center justify-center"
                onClick={handleMint}
                disabled={isMinting}
            >
                {isMinting ? (
                    <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Minting...
                    </>
                ) : (
                    'Mint for Free'
                )}
            </button>
        );
    };

    const renderAppLink = () => {
        if (!token) return null;
        if (deviceType === 'IOS') {
            return (
                <a className='bg-[#FF5900] text-white w-full my-6 px-2 py-3 font-semibold font-lg rounded-md hover:bg-[#FF5900]/80 transition duration-200' href='DayLight.app://'>
                    Return to Daylight App
                </a>
            );
        }
        if (deviceType === 'Android') {
            return (
                <a className='bg-[#FF5900] text-white w-full my-6 px-2 py-3 font-semibold font-lg rounded-md hover:bg-[#FF5900]/80 transition duration-200' href='https://co.daylightenergy.app'>
                    Return to Daylight App
                </a>
            );
        }
        return null;
    };

    return (
      <>
        <div className="max-w-2xl m-auto py-10 p-3">
            <div className="flex flex-col md:flex-row">
                <NFTImage tokenId={tokenId} key={tokenId} hasMinted={hasMinted}/>
                <div className="flex-1 md:ml-6">
                    <div>
                        <h3 className="text-xl font-semibold">Dawn of Daylight NFT</h3>
                        <p className="text-light text-gray-600 mb-2">Genesis Collection</p>
                        <p className="text-md font-medium mt-2">10 Days 24 Hours 30 Minutes</p>
                        {renderButton()}
                        {callID && <CallStatus id={callID} onConfirmed={handleConfirmed} />}
                    </div>
                    {renderAppLink()}
                    <div className="mt-4 pb-6 border-b border-gray">
                        <h2 className="text-xl font-semibold mb-2">About</h2>
                        <p className="text-md font-light">
                            We're building a cooperative ecosystem that capitalizes, constructs, and coordinates decentralized energy resources globally. And it starts with you.
                        </p>
                    </div>
                    <BenefitsList />
                    <div className="mt-4">
                        <h3 className="text-xl font-bold mb-2">Terms</h3>
                        <p className="text-md font-light">
                            We're building a cooperative ecosystem that capitalizes, constructs, and coordinates decentralized energy resources globally. And it starts with you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
                      
        <div className='rounded-md p-3 max-w-36 sticky-qr'>
          <h6 className='text-md font-medium text-white text-center mb-2'>Download the Daylight App</h6>
          <div className="md:mt-3 lg:m-0">
            <img alt="Download the Daylight App QR Code" src={qrCode} />
          </div>
        </div>
      </>
    );
}

export default NFTDetails;