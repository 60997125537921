import { baseSepolia, base } from 'viem/chains';
import { Environment, getCurrentEnvironment } from './environment';

/**
 * @typedef {Object} Chain
 * @property {number} id - The chain ID
 * @property {string} name - The name of the chain
 */

/**
 * The list of supported Chains for a given environment
 * @type {Object.<string, Chain[]>}
 */
export const SUPPORTED_CHAINS = {
  [Environment.localhost]: [baseSepolia],
  [Environment.development]: [baseSepolia],
  [Environment.staging]: [base, baseSepolia],
  [Environment.production]: [base, baseSepolia],
};

/**
 * Gets the list of supported chains for a given environment.
 * Defaults to the current environment.
 * @param {string} [env] - The environment to get chains for
 * @returns {Chain[]} The list of supported chains
 */
export function getChainsForEnvironment(env) {
  if (!env) {
    env = getCurrentEnvironment();
  }
  return SUPPORTED_CHAINS[env];
}

/**
 * Gets a chain by its ID
 * @param {string} chainId - The chain ID to look for
 * @returns {Chain|null} The found chain or null if not found
 */
export function getChainById(chainId) {
  const chains = getChainsForEnvironment();
  return chains.find((c) => c.id === Number(chainId)) || null;
}

// Example usage in a React component:
/*
import React from 'react';
import { getChainsForEnvironment, getChainById } from './supportedChains';

function ChainInfo() {
  const currentChains = getChainsForEnvironment();
  const specificChain = getChainById('8453'); // Base chain ID

  return (
    <div>
      <h2>Supported Chains:</h2>
      <ul>
        {currentChains.map((chain) => (
          <li key={chain.id}>{chain.name} (ID: {chain.id})</li>
        ))}
      </ul>
      {specificChain && (
        <p>Specific Chain: {specificChain.name} (ID: {specificChain.id})</p>
      )}
    </div>
  );
}

export default ChainInfo;
*/