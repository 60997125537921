import React from 'react';

function FooterColumn({ title, items, copyright }) {
  return (
    <div className="flex flex-col mb-6 md:mb-0">
      {title && <h3 className="text-lg font-semibold">{title}</h3>}
      {copyright && <p className="text-muted">{copyright}</p>}
      <ul className="mt-2">
        {items.map((item, index) => (
          <li key={index}>
            <a 
              href={item.url} 
              className="text-muted font-light leading-loose"
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterColumn;