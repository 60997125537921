import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
  const [deviceType, setDeviceType] = useState('unknown');

  useEffect(() => {
    const detectDevice = () => {
      if (typeof window !== 'undefined' && window.navigator) {
        const userAgent = window.navigator.userAgent.toLowerCase();
        console.log('User Agent:', userAgent); // Logging user agent for debugging

        if (/android/i.test(userAgent)) {
          setDeviceType('Android');
        } else if (
          /ipad|iphone|ipod/.test(userAgent) && 
          !window.MSStream
        ) {
          setDeviceType('IOS');
        } else if (/win/.test(userAgent)) {
          setDeviceType('Windows');
        } else if (/mac/.test(userAgent)) {
          setDeviceType('Mac');
        } else if (/linux/.test(userAgent)) {
          setDeviceType('Linux');
        } else {
          setDeviceType('Other');
        }
      } else {
        console.log('Window or navigator not available');
      }
    };

    detectDevice();
    console.log('Detected Device Type:', deviceType); // Logging detected device type
  }, []);

  return deviceType;
};

export default useDeviceDetect;