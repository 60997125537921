import React from 'react';
import Logo from './Logo';
import { useAccount } from 'wagmi';
import { Avatar } from '@coinbase/onchainkit/identity';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { clsx } from 'clsx';
import { AccountInfoPanel } from './account/AccountInfoPanel';

const DropdownMenuContentStyle = {
  marginTop: '-22px',
};

function Header() {
    const { address } = useAccount();
  return (
    <header className="flex justify-between items-center md:p-6 p-2">
      <Logo />
      {/* <div className="logo">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="8" fill="black" fillOpacity="0.08"/>
          <path d="M29.5 20.25H10" stroke="black" strokeWidth="1.91" strokeMiterlimit="10"/>
          <path d="M29.5 15H10" stroke="black" strokeWidth="1.91" strokeMiterlimit="10"/>
          <path d="M29.5 24.75H19" stroke="black" strokeWidth="1.92" strokeMiterlimit="10"/>
        </svg>
      </div> */}
      <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div className="flex h-8 w-8 items-center justify-center">
          {(
            <button type="button" aria-label="Disconnect">
              <div className="logo">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="8" fill="black" fillOpacity="0.08"/>
                <path d="M29.5 20.25H10" stroke="black" strokeWidth="1.91" strokeMiterlimit="10"/>
                <path d="M29.5 15H10" stroke="black" strokeWidth="1.91" strokeMiterlimit="10"/>
                <path d="M29.5 24.75H19" stroke="black" strokeWidth="1.92" strokeMiterlimit="10"/>
                </svg>
            </div>
            </button>
          )}
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          sideOffset={40}
          className={clsx(
            'h-42 inline-flex w-60 flex-col items-start justify-start',
            'rounded-lg bg-neutral-900 bg-opacity-90 px-6 pb-2 pt-6 shadow backdrop-blur-2xl',
          )}
          style={DropdownMenuContentStyle}
        >
         <ul className='mb-2'>
            <li>
                <a href='https://godaylight.com/' className='dropdown-link'>Home</a>
            </li>
            <li>
                <a href='https://godaylight.com/monitor/' className='dropdown-link'>Products</a>
            </li>
            <li>
                <a href='https://apps.apple.com/us/app/daylight-energy/id6450997694' className='dropdown-link'>Download App</a>
            </li>
         </ul>
         {address && <hr className="h-px self-stretch border-transparent bg-zinc-400 bg-opacity-20" />}

         {address &&  <AccountInfoPanel />}

        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>

    </header>
  );
}

export default Header;