import { useState, useEffect } from 'react';

const useTokenFromUrl = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const extractTokenFromUrl = (url) => {
      const urlParams = new URLSearchParams(url);
      return urlParams.get('token');
    };

    const handleUrl = () => {
      const currentUrl = window.location.search;
      const extractedToken = extractTokenFromUrl(currentUrl);
      if (extractedToken) {
        setToken(extractedToken);
        console.log('Token received:', extractedToken);
      }
    };

    // Handle the initial URL
    handleUrl();

    // Listen for popstate event (browser back/forward navigation)
    const handlePopState = () => {
      handleUrl();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return token;
};

export default useTokenFromUrl;