import React from 'react';
import Header from './components/Header';
import NFTDetails from './components/NFTDetails';
import Footer from './components/Footer';
import OnchainProviders from './OnchainProviders';


import '@radix-ui/themes/styles.css';


function App() {
  return (
    <OnchainProviders>
    <div className="App">
      <div className="container mx-auto">
        <Header />
        <NFTDetails />
      </div>
      <Footer />
    </div>
    </OnchainProviders>
  );
}

export default App;